import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { MDBBootstrapModule } from "angular-bootstrap-md";

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";


import { APIInterceptor } from "./_helpers/api.interceptor";
import { ErrorInterceptor } from "./_helpers/error.interceptor";
import { LoaderInterceptor } from "./_helpers/loader.interceptor";

import { LayoutModule } from "./layout/layout.module";
import { AuthModule } from "./auth/auth.module";
import { LobbyModule } from "./lobby/lobby.module";
import { UserBalanceModule } from "./finance/user-balance/user-balance.module";

import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { EncryptService } from "./_services/encrypt.service";
import { StorageService } from "./_services/storage.service";
import { LoaderService } from "./_services/loader.service";

import { ProfileModule } from "./user/profile/profile.module";
import { NotificationModule } from "./notification/notification.module";
import { AccountModule } from "./my-account/my-account.module";

import { changePasswordModule } from "./user/change-password/change-password.module";

import { LoaderComponent } from "./loader/loader.component";

import { FinanceComponent } from "./finance/finance.component";
import { UserComponent } from "./user/user.component";
import { ServiceWorkerModule } from "@angular/service-worker";

const routes: Routes = [
  {
    path: "",
    redirectTo: "",
    pathMatch: "full"
  },
  // otherwise redirect to home
  {
    path: "**",
    redirectTo: ""
  }
];

// required for AOT compilation
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/translate/", ".json");
}

@NgModule({
    declarations: [
        AppComponent,
        LoaderComponent,
        FinanceComponent,
        UserComponent
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CommonModule,
        BrowserModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MDBBootstrapModule.forRoot(),
        // ngx-translate and the loader module
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        RouterModule.forRoot(routes),
        LayoutModule,
        AuthModule,
        UserBalanceModule,
        LobbyModule,
        ProfileModule,
        NotificationModule,
        AccountModule,
        changePasswordModule,
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.production
        })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // {
        //   provide: 'SocialAuthServiceConfig',
        //   useValue: {
        //     autoLogin: false,
        //     providers: [
        //       {
        //         id: GoogleLoginProvider.PROVIDER_ID,
        //         provider: new GoogleLoginProvider(`${environment.googleId}`)
        //       },
        //       {
        //         id: FacebookLoginProvider.PROVIDER_ID,
        //         provider: new FacebookLoginProvider(`${environment.fbId}`)
        //       }
        //     ],
        //     onError: (err) => {
        //       console.error(err);
        //     }
        //   } as SocialAuthServiceConfig,
        // },
        EncryptService,
        StorageService,
        LoaderService,
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
    ],
    bootstrap: [AppComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class AppModule {}
