import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatListModule } from '@angular/material/list';


import { LandingLayoutComponent } from './landing-layout/landing-layout.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';

import { AlertComponent } from '../alert/alert.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    RouterModule.forChild([])
  ],
  exports: [
  ],
  declarations: [
    AlertComponent,
    LandingLayoutComponent,
    MainLayoutComponent,
  ]
})
export class LayoutModule { }
