import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyAccountComponent } from './my-account.component';
import { AccountRoutingModule } from './my-account-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppPipesModule } from '../pipes/app.pipes.module';

@NgModule({
    imports: [
        CommonModule,
        AccountRoutingModule,
        TranslateModule,
        MatTabsModule,
        InfiniteScrollModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        AppPipesModule
    ],
    declarations: [MyAccountComponent],
    exports: [MyAccountComponent]
})
export class AccountModule { }
