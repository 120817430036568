import { Component, OnInit, Injectable, ViewEncapsulation } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';


import { User } from '../models/user';
import { AuthenticationService } from '../_services/authentication.service';
import { LobbyService } from '../_services/lobby.service';
import { StorageService } from '../_services/storage.service';
import { UserService } from '../_services/user.service';

@Component({
    templateUrl: './lobby.component.html',
    styleUrls: ['./lobby.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@Injectable({ providedIn: 'root' })
export class LobbyComponent implements OnInit {
    currentUser: User;
    users = [];
    match_list: any = [];
    league_list: any;
    season_game_uid: string = '';
    sport_id = this.storage.getLocalStorage('selectedSport').sports_id;
    showFilter = false;
    dialogref;
    trackByMatch;
    user_balance: any;
    url: string;
    wurl: string;
    session_key = this.storage.getLocalStorage('ngStorage-user').session_key;
    user_id = this.storage.getLocalStorage('ngStorage-user').user_profile['user_id'];
    winref: any;
    profiledata: any;
    browser: string;

    constructor(
        private authenticationService: AuthenticationService,
        private lobbyService: LobbyService,
        private storage: StorageService,
        private router: Router,
        public dialog: MatDialog,
        private userService: UserService
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }


    ngOnInit() {
        this.getBalance();
        this.browser = this.getBrowserName();
        // this.getAllMatches();
    }

    getBalance() {
        let data = { session_key: this.session_key };
        this.userService.getUserBalance(data)
            .pipe(first())
            .subscribe(
                data => {
                    let balance = data.data.user_balance;
                    let total = +balance['real_amount'] + +balance['winning_amount'];

                    let currentUser = this.storage.getLocalStorage('ngStorage-user');
                    this.profiledata = currentUser.user_profile;
                    currentUser.user_profile['balance'] = total;
                    this.storage.setLocalStorage('ngStorage-user', currentUser);

                    this.user_balance = total;
                })
    }

    /*
        getAllMatches is used to get the filtered/all lobby matches
        @param : leagueId (optional) : Used to get filtered data
    */
    getAllMatches() {
        this.lobbyService.getLobbyMatches()
            .pipe(first())
            .subscribe(
                data => {
                    this.match_list = data;
                },
            );
    }

    getDataforGameSelected($event) {
        switch ($event.index) {
            case 0: {
                this.getAllMatches();
                break;
            }
            case 1: {
                this.getAllMatches();
                break;
            }
            default: {
                this.getAllMatches();
                break;
            }
        }
    }

    receiveFilterLeague($event: string) {
        this.showFilter = false;
        this.getAllMatches();
    }

    receiveSeasonGameUid($event: string) {
        this.season_game_uid = $event;
        this.router.navigate(['/contest', $event]);
    }

    logOut() {
        this.authenticationService.logout()
            .pipe(first())
            .subscribe(
                data => {
                    this.router.navigate(['']);
                });
    }

    addition(num1: number, num2: number, num3: number) {
        return +num1 + +num2 + +num3;
    }

    // OPEN PLAY NOW POP-UP
    playNowWindowOpen() {
        // var playNowWindowFeatures = "scrollbars=0,resizable=0,toolbar=0,location=0,status=0,menubar=0,top=0,left=0,width=1700,height=850";

        // this.winref = window.open('', 'playNowWindow', playNowWindowFeatures, false);
        // console.log(this.winref.location.href);
        // if (this.winref.location.href === 'about:blank') {
        // console.log(this.winref.location);
        // this.winref.location.href = 'game/index.html';
        // this.winref.focus();
        // } else {
        // this.winref.focus();
        // }
        window.location.href = 'teen-patti/index.html';
    }

    getBrowserName(): string {
        const agent = window.navigator.userAgent.toLowerCase();
        const browser =
            agent.indexOf('edge') > -1 ? 'MICROSOFT EDGE'
                : agent.indexOf('edg') > -1 ? 'CHROMIUM-BASED EDGE'
                    : agent.indexOf('opr') > -1 ? 'OPERA'
                        : agent.indexOf('chrome') > -1 ? 'CHROME'
                            : agent.indexOf('trident') > -1 ? 'INTERNET EXPLORER'
                                : agent.indexOf('firefox') > -1 ? 'IREFOX'
                                    : agent.indexOf('safari') > -1 ? 'SAFARI'
                                        : 'other';

        return browser;
    }
}



