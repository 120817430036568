import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainLayoutComponent } from '../layout/main-layout/main-layout.component';
import { MyAccountComponent } from './my-account.component';
import { AuthGuard } from '../_helpers/auth.guard';


const routes: Routes = [
  {
    path: 'deposit',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: MyAccountComponent }
    ]
  },
  {
    path: 'history',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: MyAccountComponent }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
